<template>
  <div class="managingPeople hp100 bbox " v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col>
          <el-button
            plain
            class="add-column-btn bbox"
            @click="gotoDetail('')"
            type="primary"
          >
            <i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
        </el-col>
        <el-col>
          <div class="flex align-center justify-end header-search-box">
            <el-input
              style="width:200px"
              placeholder="请输入管理员名称"
              clearable
              v-model="queryInfo.condition.adminName"
              class="header-search-input ml10"
            >
            </el-input>
            <el-input
              style="width:200px"
              placeholder="请输入管理员电话"
              clearable
              v-model="queryInfo.condition.adminPhone"
              class="header-search-input ml10"
            >
            </el-input>
            <!-- <el-input
              style="width:200px"
              placeholder="请输入管理员身份证ID"
              clearable
              v-model="queryInfo.condition.adminCardId"
              class="header-search-input ml10"
            >
            </el-input> -->
            <el-button
              slot="append"
              icon="el-icon-search"
              class="textblue searchBtn"
              @click="search()"
            ></el-button>
          </div>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <el-table-column label="管理员头像" align="center" prop="adminLogo">
          <template slot-scope="scope">
            <img :src="scope.row.adminLogo" alt="" style="width:40%" />
          </template>
        </el-table-column>
        <el-table-column label="管理员名称" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.adminName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="管理员账号" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.adminAccount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="微信号" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.wechatNum }}</span>
          </template>
        </el-table-column>
        <el-table-column label="身份证正面" align="center" prop="">
          <template slot-scope="scope">
            <img :src="scope.row.idCardFrontSide" alt="" style="width:40%" />
          </template>
        </el-table-column>
        <el-table-column label="身份证反面" align="center" prop="">
          <template slot-scope="scope">
            <img :src="scope.row.idCardSecondSide" alt="" style="width:40%" />
          </template>
        </el-table-column>
        <el-table-column label="管理员电话" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span>{{ scope.row.adminPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分管设备数量" align="center" prop="名称">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceNumber || "0" }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="管理员身份证ID"
          align="center"
          prop="orderPrice"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.adminCardId }}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="创建时间" align="center" prop="orderPrice">
          <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{
              scope.row.createDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="查看详情"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16 ml20"
                @click="gotoDetail(scope.row.id)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
                @click="del(scope.row.id)"
              ></i>
            </el-tooltip>
            <!-- <el-button type="primary"> 二维码 </el-button> -->
          </template>
        </el-table-column>
        <!-- <el-table-column
                        label="操作"
                        align="center"
                        class-name="small-padding fixed-width"
                >
                    <template slot-scope="scope">
                        <el-tooltip
                                class="item"
                                effect="dark"
                                content="查看详情"
                                placement="bottom"
                        >
                            <el-button type="primary" icon="el-icon-info" circle
                                    @click="gotoDetail(scope.row.id, scope.row.userCode)"
                         ></el-button>
                        </el-tooltip>                       
                    </template>
                </el-table-column> -->
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",

      loading: true,
      id: "",
      // 获取用户列表查询参数对象
      tableData: [],
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          keyWord: "",
          adminName: "",
          adminPhone: "",
          adminCardId: ""
        }
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false
    };
  },
  created() {
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //条件查询
    search() {
      this.getListData();
    },
    gotoDetail(id) {
      this.$router.push({
        path: "/carManager/details",
        query: {
          id
        }
      });
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    // 监听排序
    sortChange() {},

    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/userAdmin/delete", { id: id })
          .then(function(response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      // 获取列表
      var that = this;

      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/userAdmin/list", {
          currPage: data.currPage,
          pageSize: data.pageSize,
          condition: that.queryInfo.condition
        })
        .then(function(response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
